@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/GilroyMedium.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/GilroySemiBold.woff2") format("woff2");
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Gilroy', sans-serif;
  font-size: 1.25em;
  line-height: 1.3;
  width: 100%;
  min-width: 320px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
  background-color: #0A0A0C;
  font-weight: 500;
}

header,
footer {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

main {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  font-style: normal;
  margin: 0;
}

p.ninja {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-style: normal;
  margin: 0;
}

h1 {
  font-size: 30px;
}

.heading {
  margin-bottom: 45px;
}

.heading h2 {
  font-size: 50px;
  line-height: 95px;
  text-transform: capitalize;
}

.heading h3 {
  font-size: 48px;
  line-height: 57px;
  text-transform: capitalize;
  margin-top: 20px;
}

.heading p {
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  margin-top: 20px;
}

label {
  margin-bottom: 0;
}

.btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 15px 64px 15px 30px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  background: -o-linear-gradient(283.8deg, #EA69FF 7.98%, #7003FB 81.61%);
  background: linear-gradient(166.2deg, #EA69FF 7.98%, #7003FB 81.61%);
  position: relative;
  border: none;
}

.btn:after {
  position: absolute;
  content: '';
  background-size: contain;
  background-position: 86% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/check_w.svg");
  width: 24px;
  height: 25px;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
}

.btn:hover {
  color: #fff;
  background: -o-linear-gradient(283.8deg, #7003FB 7.98%, #EA69FF 81.61%);
  background: linear-gradient(166.2deg, #7003FB 7.98%, #EA69FF 81.61%);
}

.btn.btn-submit {
  padding: 15px 30px;
}

.btn.btn-submit:after {
  display: none;
}

.btn.btn-line {
  background: -o-linear-gradient(#0A0A0C, #0A0A0C) padding-box, -o-linear-gradient(283.8deg, #EA69FF 7.98%, #7003FB 81.61%) border-box;
  background: linear-gradient(#0A0A0C, #0A0A0C) padding-box, linear-gradient(166.2deg, #EA69FF 7.98%, #7003FB 81.61%) border-box;
  border: 1px solid transparent;
}

.btn.btn-line:after {
  background-image: url("/public/img/arrow-right.svg");
}

.btn.btn-line:hover {
  color: #fff;
  background: -o-linear-gradient(283.8deg, #7003FB 7.98%, #EA69FF 81.61%) !important;
  background: linear-gradient(166.2deg, #7003FB 7.98%, #EA69FF 81.61%) !important;
}

.btn.btn-more:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn.btn-less:after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

button {
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button:active,
button:focus {
  outline: none;
}

*:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.center {
  text-align: center;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

input {
  border-radius: 0;
}

input.wpcf7-submit {
  cursor: pointer;
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
}

/*****************  ARROW UP  **************/

/********************  MODAL  **********************/

.fancybox__backdrop {
  background: rgba(10, 10, 12, 0.5);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.carousel__button {
  top: 0 !important;
  color: #A1A1AA !important;
}

.modal {
  max-width: 528px;
  width: 100%;
  background-color: #18181B;
  -webkit-box-shadow: 0px 12.5714px 50.2857px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 12.5714px 50.2857px rgba(0, 0, 0, 0.15);
}

.modal .content img {
  margin-bottom: 25px;
}

.modal .content h2 {
  text-transform: uppercase;
  margin-bottom: 25px;
  color: #fff;
}

.modal .content p {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 25px;
}

.modal .content button {
  width: 135px;
  height: 54px;
  background-color: transparent;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
}

.modal .content button:hover {
  background-color: #fff;
  color: #18181B;
}

/*********************  HEADER  ************************/

header {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/1_3.svg");
  padding-bottom: 390px;
  position: relative;
}

header .header__top {
  background-color: #0A0A0C;
  padding-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 146px;
}

header .header__top .free {
  font-weight: 600;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
  margin-left: 30px;
  color: #18171B;
  text-transform: capitalize;
  background-color: #fff;
  padding: 15px 64px 15px 30px;
  background-size: 24px;
  background-position: 86% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/check.svg");
}

header .header__top .header__menu .menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

header .header__top .header__menu .menu ul {
  text-align: right;
}

header .header__top .header__menu .menu ul li {
  display: inline-block;
  margin: 0 30px;
}

header .header__top .header__menu .menu ul li a {
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(currentColor), to(currentColor));
  background-image: -o-linear-gradient(currentColor, currentColor);
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  -webkit-transition: background-size .3s;
  -o-transition: background-size .3s;
  transition: background-size .3s;
}

header .header__top .header__menu .menu ul li a:hover {
  background-size: 100% 1px;
}

header .header__top .header__menu .header_burger {
  position: relative;
  display: none;
  float: right;
}

header .header__top .header__menu .header_burger .burger_button {
  position: relative;
  top: 2px;
  width: 30px;
  height: 30px;
  display: block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 20;
}

header .header__top .header__menu .header_burger .burger_button span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

header .header__top .header__menu .header_burger .burger_button span:nth-child(1) {
  top: 4px;
}

header .header__top .header__menu .header_burger .burger_button span:nth-child(2) {
  top: 12px;
}

header .header__top .header__menu .header_burger .burger_button span:nth-child(3) {
  top: 20px;
}

header .header__top .header__menu .header_burger .burger_button.open span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

header .header__top .header__menu .header_burger .burger_button.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

header .header__top .header__menu .header_burger .burger_button.open span:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

header .header__top .header__menu .header_burger .menuopen {
  -webkit-transform: translateX(0%) !important;
  -ms-transform: translateX(0%) !important;
  transform: translateX(0%) !important;
}

header .header__top .header__menu .header_burger .menu_toggle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0A0A0C;
  margin: 0;
  overflow: auto;
  text-align: center;
  z-index: 11;
  padding-top: 50px;
  top: 0;
  padding-bottom: 70px;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

header .header__top .header__menu .header_burger .menu_toggle .toggle__inner {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
}

header .header__top .header__menu .header_burger .menu_toggle .toggle__inner .free {
  margin: 15px auto !important;
  font-size: 20px;
}

header .header__top .header__menu .header_burger .menu_toggle .toggle__inner ul li {
  margin-bottom: 13px;
}

header .header__top .header__menu .header_burger .menu_toggle .toggle__inner ul li a {
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(currentColor), to(currentColor));
  background-image: -o-linear-gradient(currentColor, currentColor);
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  -webkit-transition: background-size .3s;
  -o-transition: background-size .3s;
  transition: background-size .3s;
  font-size: 20px;
}

header .header__top .header__menu .header_burger .menu_toggle .toggle__inner ul li a:hover {
  background-size: 100% 1px;
}

header .header__main .main__content h2 {
  font-size: 34px;
  line-height: 42px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

header .header__main .main__content h1 {
  font-size: 80px;
  line-height: 98px;
  text-transform: capitalize;
  margin-bottom: 40px;
}

header .header__main .main__content p {
  max-width: 793px;
  width: 100%;
  margin: 0 auto 40px auto;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
}

header .header__main .main__content .box__btn .btn {
  margin: 7px;
}

header .header__main .main__content .box__btn .btn-line {
  background: -o-linear-gradient(#232739, #232739) padding-box, -o-linear-gradient(283.8deg, #EA69FF 7.98%, #7003FB 81.61%) border-box;
  background: linear-gradient(#232739, #232739) padding-box, linear-gradient(166.2deg, #EA69FF 7.98%, #7003FB 81.61%) border-box;
}

header .main__image {
  text-align: center;
  position: absolute;
  bottom: -240px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}

header .main__image .image__inner {
  position: relative;
}

header .main__image .image__inner:after {
  position: absolute;
  content: '';
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/1_1.png");
  width: 197px;
  height: 214px;
  bottom: 30px;
  right: 420px;
}

header .main__image img {
  max-width: 1205px;
  width: 100%;
}

/*****************************  clients  ***********************/

#clients {
  padding-top: 400px;
  margin-bottom: 220px;
}

#clients .clients_heading {
  max-width: 521px;
  width: 100%;
  margin: 0 auto 70px auto;
}

#clients .clients_heading h2 {
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
}

#clients .box {
  margin-bottom: 20px;
}

#clients .box .box__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*****************************  about  *************************/

#about {
  margin-bottom: 200px;
}

#about .heading {
  margin-bottom: 200px;
}

#about .heading h3 {
  max-width: 939px;
  width: 100%;
  margin: 20px auto 0 auto;
}

#about .tab__nav .nav__inner {
  height: 390px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#about .tab__main .main__inner {
  height: 390px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

#about .tabs .tabs__caption {
  text-align: right;
}

#about .tabs .tabs__caption li {
  cursor: pointer;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  background: -o-linear-gradient(0.07deg, #333232 -0.05%, #737373 100.04%);
  background: linear-gradient(89.93deg, #333232 -0.05%, #737373 100.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
  position: relative;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
}

#about .tabs .tabs__caption li:after {
  position: absolute;
  content: '';
  width: 3px;
  height: 0px;
  background-color: #fff;
  top: 45px;
  right: 3px;
  -webkit-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in;
}

#about .tabs .tabs__caption li.active {
  color: #fff;
  background: -o-linear-gradient(0.07deg, #fff -0.05%, #fff 100.04%);
  background: linear-gradient(89.93deg, #fff -0.05%, #fff 100.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  background-clip: text;
  text-fill-color: #fff;
  padding-bottom: 60px;
}

#about .tabs .tabs__caption li.active:after {
  height: 55px;
}

#about .tabs .tabs__caption li:last-child:after {
  display: none !important;
}

#about .tabs .tabs__content {
  display: none;
}

#about .tabs .tabs__content.active {
  display: block;
}

#about .tabs .tabs__content .content__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#about .tabs .tabs__content .content__inner .content {
  max-width: 605px;
  width: 100%;
}

#about .tabs .tabs__content .content__inner .content h3 {
  font-size: 65px;
  line-height: 78px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

#about .tabs .tabs__content .content__inner .content p {
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
}

#about .tabs .tabs__content .content__inner .content ul li {
  position: relative;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 39px;
  margin-bottom: 15px;
}

#about .tabs .tabs__content .content__inner .content ul li:after {
  position: absolute;
  content: '';
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/check_w.svg");
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
}

#about .tabs .tabs__content .content__inner .image {
  max-width: 539px;
  width: 100%;
  text-align: center;
}

#about .tabs .tabs__content .content__inner .image img {
  max-height: 375px;
}

/********************************  ourtech  ***************************/

#ourtech {
  margin-bottom: 200px;
}

#ourtech .heading p {
  max-width: 659px;
  width: 100%;
  margin: 20px auto 0 auto;
}

#ourtech .ourtech__main {
  border-top: 5px solid #2E2E2E;
  padding-top: 33px;
}

#ourtech .ourtech__main .box {
  margin-bottom: 45px;
  /* display: none; */
}

#ourtech .ourtech__main .box .box__inner .icon {
  margin-bottom: 12px;
}

#ourtech .ourtech__main .box .box__inner .title {
  display: inline-block;
  color: #fff;
  margin-bottom: 12px;
  position: relative;
  padding-right: 39px;
}

#ourtech .ourtech__main .box .box__inner .title:after {
  position: absolute;
  content: '';
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/icon_8.svg");
  width: 24px;
  height: 24px;
  top: 1px;
  right: 0;
  -webkit-transition: .45s all;
  -o-transition: .45s all;
  transition: .45s all;
}

#ourtech .ourtech__main .box .box__inner .title:hover:after,
#ourtech .ourtech__main .box .box__inner .title:active:after {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

#ourtech .ourtech__main .box .box__inner .title h5 {
  color: inherit;
  font-size: 24px;
  line-height: 28px;
}

#ourtech .ourtech__main .box__btn .btn-more {
  margin-top: 50px;
  cursor: pointer;
}

#ourtech .ourtech__main .box__btn .btn-less {
  margin-top: 50px;
  cursor: pointer;
}

/**************************  projects  ************************/

#projects {
  margin-bottom: 140px;
}

#projects .heading p {
  max-width: 605px;
  width: 100%;
  margin: 20px auto 0 auto;
}

#projects .row {
  margin-left: -30px;
  margin-right: -30px;
  padding-top: 80px;
}

#projects .item {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
}

#projects .item:nth-child(2n+1) {
  margin-top: -75px;
}

#projects .item .item__inner {
  display: block;
  overflow: hidden;
}

#projects .item .item__inner img {
  will-change: transform;
  -webkit-transition: 1s all;
  -o-transition: 1s all;
  transition: 1s all;
}

#projects .item .item__inner:hover img {
  opacity: 0.7;
}

/***************************  contacts  ***********************/

#contacts {
  overflow: hidden;
}

#contacts .contacts__heading {
  background-color: #141315;
  position: relative;
}

#contacts .contacts__heading:before {
  position: absolute;
  content: '';
  width: 2000px;
  height: 100%;
  background-color: #141315;
  top: 0;
  bottom: 0;
  left: -1999px;
  margin: auto;
}

#contacts .contacts__heading:after {
  position: absolute;
  content: '';
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("/public/img/icon_9.svg");
  width: 960px;
  height: 686px;
  bottom: 0;
  left: -205px;
}

#contacts .contacts__heading .heading__inner {
  padding-top: 100px;
}

#contacts .contacts__heading .heading__inner h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

#contacts .contacts__form {
  border-left: 2px solid #1B1A1C;
  position: relative;
}

#contacts .contacts__form:before {
  position: absolute;
  content: '';
  width: 2000px;
  height: 2px;
  background-color: #1B1A1C;
  top: 0;
  right: -1245px;
}

#contacts .contacts__form:after {
  position: absolute;
  content: '';
  width: 2000px;
  height: 2px;
  background-color: #1B1A1C;
  bottom: 0;
  right: -1245px;
}

#contacts .contacts__form .form__inner {
  padding-left: 70px;
  padding-top: 270px;
  padding-bottom: 200px;
}

#contacts .contacts__form .form__inner form .form__item {
  margin-bottom: 30px;
}

#contacts .contacts__form .form__inner form .form__item .item__inner label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 8px;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input {
  font-weight: 600;
  border-radius: 0;
  border: none;
  padding-right: 10px;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: .25s all;
  -o-transition: .25s all;
  transition: .25s all;
  height: 48px;
  color: rgba(255, 255, 255, 0.4);
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-ms-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner input:focus {
  border-bottom: 1px solid white;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea {
  font-weight: 600;
  border-radius: 0;
  border: none;
  padding-right: 10px;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: .25s all;
  -o-transition: .25s all;
  transition: .25s all;
  height: 48px;
  color: rgba(255, 255, 255, 0.4);
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-ms-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

#contacts .contacts__form .form__inner form .form__item .item__inner textarea:focus {
  border-bottom: 1px solid white;
}

#contacts .contacts__form .form__inner form .form__submit {
  margin-top: 60px;
}

#contacts .contacts__form .form__inner .main__contacts {
  margin-top: 70px;
}

#contacts .contacts__form .form__inner .main__contacts h5 {
  font-weight: 600;
  color: #FFFFFF;
  opacity: 0.2;
  margin-bottom: 15px;
}

#contacts .contacts__form .form__inner .main__contacts a {
  font-weight: 600;
  color: #fff;
}

/***************************  footer  *************************/

#footer {
  padding-top: 110px;
  padding-bottom: 40px;
}

#footer .footer__item {
  margin-bottom: 40px;
}

#footer .footer__menu .menu ul {
  text-align: center;
}

#footer .footer__menu .menu ul li {
  display: inline-block;
  margin: 0 30px;
}

#footer .footer__menu .menu ul li a {
  font-size: 22px;
  line-height: 26px;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(currentColor), to(currentColor));
  background-image: -o-linear-gradient(currentColor, currentColor);
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  -webkit-transition: background-size .3s;
  -o-transition: background-size .3s;
  transition: background-size .3s;
}

#footer .footer__menu .menu ul li a:hover {
  background-size: 100% 1px;
}

#footer .footer__contacts .contacts ul {
  text-align: right;
}

#footer .footer__contacts .contacts ul li {
  display: inline-block;
  margin-left: 20px;
}

#footer .footer__contacts .contacts ul li a:hover {
  opacity: 0.8;
}

@media (min-width: 992px) {
  #about .tab__nav {
    width: 18%;
  }

  #about .tab__main {
    width: 82%;
  }
}

@media (min-width: 1200px) {
  #about .tab__nav {
    width: 15%;
  }

  #about .tab__main {
    width: 85%;
  }
}

@media (min-width: 1600px) {
  #about .tab__nav {
    width: 12%;
  }
}

@media all and (max-width: 1600px) {
  body {
    font-size: 1.125em;
  }

  .heading h2 {
    font-size: 60px;
    line-height: 1.2;
  }

  .heading h3 {
    font-size: 38px;
    line-height: 1.2;
  }

  header .header__top {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 100px;
  }

  header .header__top .header__menu .menu ul li {
    margin: 0 20px;
  }

  header .main__image {
    bottom: -100px;
  }

  header .main__image .image__inner:after {
    width: 140px;
    height: 190px;
    right: 330px;
    bottom: 0;
  }

  header .main__image img {
    width: 80%;
  }

  #clients {
    padding-top: 200px;
    margin-bottom: 140px;
  }

  #about {
    margin-bottom: 140px;
  }

  #about .heading {
    margin-bottom: 140px;
  }

  #about .tabs .tabs__caption li {
    font-size: 18px;
    margin-bottom: 10px;
  }

  #about .tabs .tabs__caption li:after {
    top: 42px;
  }

  #about .tabs .tabs__caption li.active {
    padding-bottom: 50px;
  }

  #about .tabs .tabs__caption li.active:after {
    height: 45px;
  }

  #about .tabs .tabs__content .content__inner .content h3 {
    font-size: 50px;
    line-height: 1.2;
  }

  #about .tabs .tabs__content .content__inner .content p {
    font-size: 20px;
    line-height: 1.2;
  }

  #about .tabs .tabs__content .content__inner .content ul li {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  #ourtech {
    margin-bottom: 140px;
  }

  #projects {
    margin-bottom: 80px;
  }

  #contacts .contacts__heading:after {
    width: 775px;
    height: 570px;
    background-position: 50% 100%;
  }

  #contacts .contacts__form:before {
    right: -1430px;
  }

  #contacts .contacts__form:after {
    right: -1430px;
  }

  #contacts .contacts__form .form__inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #contacts .contacts__form .form__inner form .form__submit {
    margin-top: 20px;
  }

  #contacts .contacts__form .form__inner .main__contacts {
    margin-top: 50px;
  }

  #footer {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

@media all and (max-width: 1199px) {
  .heading h2 {
    font-size: 50px;
  }

  .heading h3 {
    font-size: 30px;
  }

  header {
    padding-bottom: 80px;
  }

  header .header__top .free {
    padding: 13px 44px 13px 25px;
    margin-left: 20px;
  }

  header .header__top .header__menu .menu ul li {
    margin: 0 15px;
  }

  header .header__main .main__content h1 {
    font-size: 60px;
    line-height: 1.2;
  }

  header .main__image {
    bottom: 0px;
    position: relative;
    margin-top: 40px;
  }

  header .main__image .image__inner:after {
    width: 100px;
    height: 150px;
    right: 280px;
  }

  #clients {
    padding-top: 80px;
    margin-bottom: 100px;
  }

  #about {
    margin-bottom: 80px;
  }

  #about .heading {
    margin-bottom: 80px;
  }

  #about .tab__nav .nav__inner {
    height: 320px;
  }

  #about .tab__main .main__inner {
    height: 320px;
  }

  #about .tabs .tabs__content .content__inner .content {
    max-width: 480px;
  }

  #about .tabs .tabs__content .content__inner .content h3 {
    font-size: 40px;
  }

  #about .tabs .tabs__content .content__inner .image {
    max-width: 250px;
  }

  #ourtech {
    margin-bottom: 80px;
  }

  #projects {
    margin-bottom: 40px;
  }

  #contacts .contacts__heading:after {
    width: 685px;
  }

  #contacts .contacts__form:before {
    right: -1520px;
  }

  #contacts .contacts__form:after {
    right: -1520px;
  }

  #contacts .contacts__form .form__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #footer .footer__menu .menu ul {
    text-align: right;
  }

  #footer .footer__menu .menu ul li {
    margin: 0 0 0 35px;
  }

  #footer .footer__menu .menu ul li a {
    font-size: 16px;
  }
}

@media all and (max-width: 991px) {
  header {
    padding-bottom: 60px;
  }

  header .header__top {
    margin-bottom: 60px;
  }

  header .header__top .free {
    font-size: 14px;
    padding: 10px 20px;
    background-image: none;
  }

  header .header__top .header__menu .menu ul li a {
    font-size: 14px;
  }

  header .header__main .main__content h1 {
    font-size: 50px;
  }

  header .header__main .main__content p {
    font-size: 16px;
  }

  header .main__image .image__inner:after {
    right: 200px;
    height: 130px;
  }

  #clients {
    padding-top: 60px;
    margin-bottom: 60px;
  }

  #about .heading {
    margin-bottom: 45px;
  }

  #about .tab__nav .nav__inner {
    height: auto;
  }

  #about .tab__main .main__inner {
    height: auto;
  }

  #about .tabs .tabs__caption {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }

  #about .tabs .tabs__caption li {
    display: inline-block;
    margin: 5px 15px;
    font-size: 20px;
    border: 1px solid #fff;
    padding: 5px 10px;
    background: -o-linear-gradient(0.07deg, #999999 -0.05%, #999999 100.04%);
    background: linear-gradient(89.93deg, #999999 -0.05%, #999999 100.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  #about .tabs .tabs__caption li:after {
    display: none !important;
  }

  #about .tabs .tabs__caption li.active {
    padding-bottom: 5px;
  }

  #about .tabs .tabs__caption li.active:after {
    display: none !important;
  }

  #about .tabs .tabs__content .content__inner .content {
    padding-right: 10px;
  }

  #about .tabs .tabs__content .content__inner .content h3 {
    font-size: 28px;
  }

  #about .tabs .tabs__content .content__inner .content p {
    font-size: 16px;
  }

  #about .tabs .tabs__content .content__inner .content ul li {
    font-size: 16px;
  }

  #about .tabs .tabs__content .content__inner .image {
    max-width: 200px;
  }

  #projects .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  #projects .item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }

  #contacts .contacts__heading:before {
    left: -1000px;
    z-index: -1;
  }

  #contacts .contacts__heading:after {
    display: none;
  }

  #contacts .contacts__heading .heading__inner {
    text-align: center;
    padding-top: 60px;
  }

  #contacts .contacts__form {
    border: none;
  }

  #contacts .contacts__form:before {
    display: none;
  }

  #contacts .contacts__form:after {
    right: -1000px;
  }

  #contacts .contacts__form .form__inner {
    padding-left: 0;
  }

  #footer {
    text-align: center;
  }

  #footer .footer__logo img {
    width: 200px;
  }

  #footer .footer__menu .menu ul {
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #footer .footer__menu .menu ul li {
    margin: 0 auto;
  }

  #footer .footer__contacts .contacts ul {
    text-align: center;
  }

  #footer .footer__contacts .contacts ul li {
    margin: 0 10px;
  }
}

@media all and (max-width: 767px) {
  header .header__top .header__menu .menu {
    display: none;
  }

  header .header__top .header__menu .header_burger {
    display: block;
  }

  header .main__image .image__inner:after {
    width: 80px;
    height: 90px;
    right: 150px;
  }

  #about .tabs .tabs__caption li {
    width: 100%;
    margin: 0 auto 8px auto;
  }

  #about .tabs .tabs__content .content__inner {
    display: block;
    text-align: center;
  }

  #about .tabs .tabs__content .content__inner .content {
    margin-bottom: 30px;
    padding-right: 0;
  }

  #about .tabs .tabs__content .content__inner .content ul li {
    display: inline-block;
  }

  #about .tabs .tabs__content .content__inner .image {
    margin: 0 auto;
  }

  #projects .row {
    padding-top: 30px;
  }

  #projects .item:nth-child(2n+1) {
    margin-top: -30px;
  }
}

@media all and (max-width: 575px) {
  body {
    font-size: 1em;
  }

  .heading h2 {
    font-size: 40px;
  }

  .heading h3 {
    font-size: 20px;
  }

  .heading p {
    font-size: 20px;
    line-height: 1.2;
  }

  header {
    background-image: none;
    background-color: #23283d;
  }

  header .header__main .main__content h2 {
    font-size: 26px;
    line-height: 1.2;
  }

  header .header__main .main__content h1 {
    font-size: 40px;
  }

  header .main__image {
    display: none;
  }

  #clients .clients_heading {
    margin-bottom: 50px;
  }

  #ourtech .ourtech__main .box {
    margin-bottom: 35px;
  }

  #ourtech .ourtech__main .box__btn .btn-more {
    margin-top: 30px;
  }

  #projects .row {
    padding-top: 0;
  }

  #projects .item {
    margin-bottom: 20px;
  }

  #projects .item:nth-child(2n+1) {
    margin-top: 0;
  }

  #contacts .contacts__heading .heading__inner {
    padding-top: 50px;
  }

  #contacts .contacts__form .form__inner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #contacts .contacts__form .form__inner form .form__submit {
    margin-top: 0;
  }

  #contacts .contacts__form .form__inner form .form__submit .btn-submit {
    width: 100%;
  }

  #footer .footer__logo img {
    width: 170px;
  }

  #footer .footer__menu {
    margin-bottom: 30px;
  }

  #footer .footer__menu .menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #footer .footer__menu .menu ul li {
    margin-bottom: 10px;
  }
}